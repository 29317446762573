<template lang="pug">
#app
    #nav
        navigation
    #innerContent
        keep-alive
            router-view
    #foot
        foot
</template>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

html, body {
    height: 100%;
    margin: 0;
}

#app {
    display: flex;
    flex-flow: column;
    height: 100%;
}
#nav {
    flex: 0 1 auto;
}
#innerContent {
    flex: 1 1 auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
#foot {
    flex: 0 1 60px;
}

img {
    display: block;
}

.container.growToPage {
    height: 100%;
}

a.panel-block.is-active {
    border-left: 1px solid black;
}

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>

<script>
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
export default {
    name: "app",
    components: {foot: Footer, Navigation},
    data () {
        return {
            msg: 'MSSNCTRL AUTOMATION FRONTEND'
        }
    },
    methods: {
        waitSecondsAsync: async function (sec) {
            return new Promise((resolve) => {
                setTimeout(resolve, sec*1000);
            });
        },
        test: async function () {
            await this.waitSecondsAsync(2);
            console.log("Finished waiting");
        }
    }
}
</script>
