<template lang="pug">
.container.growToPage

    h3.title.is-size-2.has-text-centered Auto Hook Manager

    .loader-box(v-if="loading")
        .loader

    .columns(v-else)
        .column.is-5
            nav.panel
                p.panel-heading.has-text-centered Hook List
                a.panel-block(v-for="h in hooks" :class="isActive(h.id)" @click="swapActive(h.id)")
                    span {{`${h.id} - ${hookToWords(h.hook)}`}}
                a.panel-block
                    button.button.is-primary.is-fullwidth(@click="testAPI") Register new Hook

        .column.is-7
            .box(v-if="activeHook.id != null")
                .level
                    .level-left
                        .level-item
                            .temp
                                h4.title.is-4 {{activeHook.hook}}
                                p.has-text-weight-bold {{`ID: #${activeHook.id}`}}
                    .level-right
                        .level-item
                            button.button.is-small(:class="editButton.classes" @click="toggleEditing") {{editButton.text}}
                hr
                .field
                    label.label Time (Min Hour * * Day/*)
                    .control
                        input.input(:readonly="!editing" type="text" v-model="activeHookCopy.cron_string" placeholder="Not set")
                .field
                    label.label Options

                .xyz(v-if="activeHookCopy.opts != null")
                    .field.is-horizontal(v-for="k in Object.keys(activeHookCopy.opts)")
                        .field-label.is-normal
                            label.label {{k}}
                        .field-body
                            .field
                                .control
                                    input.input(:readonly="!editing" type="text" v-model="activeHookCopy.opts[k]" placeholder="Not set")
                .field(v-else) None

                .field
                    label.label Is Active?
                .field
                    .switchLabel No
                    b-switch(v-model="activeHookCopy.active" size="is-small")
                    .switchLabel Yes

                hr
                .field.is-grouped.is-grouped-centered
                    button.button.is-danger RUN THIS HOOK NOW


                .field.is-grouped.is-grouped-centered



</template>

<style scoped>
.loader-box {
    display: grid;
    place-items: center;
}

.loader {
    width: 50px;
    height: 50px;
}

.xyz {
    padding-bottom: 1rem;
}

.switchLabel {
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

</style>


<script>
import BSwitch from "buefy/src/components/switch/Switch";
export default {
    name: "Index",
    components: {BSwitch},
    data () {
        return {
            loading: true,
            activeHookID: null,
            activeHookCopy: null,
            hooks: null,
            editing: false,
            editPending: false,
        }
    },
    mounted: async function () {
        this.hooks = await this.$api.generic('get', '/hooks');
        if (this.hooks.length > 0) {
            this.activeHookID = this.hooks[0].id;
            this.activeHookCopy = JSON.parse(JSON.stringify(this.activeHook));
            this.activeHookCopy.opts = JSON.parse(this.activeHookCopy.opts);
        }
        this.loading = false;
    },
    methods: {
        testAPI: async function () {
            this.$log.debug("Sending test api call");
            let res = await this.$api.generic('get', '/hooks');
            this.$log.debug(res);
        },
        isActive: function (i) {
            return {
                'is-active': i == this.activeHookID
            }
        },
        hookToWords: function (h) {
            return h.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
        },
        swapActive: function (id) {
            if (this.editPending) return;
            this.editing = false;

            this.activeHookID = id;
            this.activeHookCopy = JSON.parse(JSON.stringify(this.activeHook));
            this.activeHookCopy.opts = JSON.parse(this.activeHookCopy.opts);
        },
        toggleEditing: async function () {
            if (this.editPending) return;

            this.editing = !this.editing;
            // if (!this.editing) {
            //     this.err = null;
            //     this.editPending = true;
            //     try {
            //         await this.$api.tags.update(this.activeItemId, this.activeItemCopy);
            //         this.editPending = false;
            //     } catch (e) {
            //         this.$log.debug('Failed to update tag');
            //         this.err = this.logError(e);
            //         this.editPending = false;
            //     }
            // }
        }
    },
    computed: {
        activeHook: function () {
            return this.hooks.find(x => x.id == this.activeHookID) || {};
        },
        editButton: function () {
            return {
                classes: {
                    'is-info': (!this.editing && !this.editPending),
                    'is-danger': (this.editing || this.editPending),
                    'is-loading': this.editPending
                },
                text: this.editing ? 'SAVE' : 'EDIT'
            }
        },
    }
}
</script>
