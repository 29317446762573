<template lang="pug">
#mainblock
    h3 This is the about page
    router-link(to="/") Index Page
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style scoped>

</style>