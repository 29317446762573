<template lang="pug">
.container.growToPage
    .contentGrid
        div
            .field.is-grouped.is-grouped-centered
                h2.is-size-2.title Login
            .box#loginBox
                form.login(@submit.prevent="login")
                    .field
                        .control
                            input.input(type="text" v-model="creds.user" placeholder="Username" required)
                    .field
                        .control
                            input.input(type="password" v-model="creds.pass" placeholder="Password" required)
                    .field.is-grouped.is-grouped-centered
                        .control
                            p.help.is-danger {{loginError}}
                    .field.is-grouped.is-grouped-centered
                        .control
                            button.button(type="submit" :class="submitButton.classes") Submit


</template>

<style scoped>
.contentGrid {
    display: grid;
    place-items: center;
    height: 100%;
}
#loginBox {
    width: 400px;
    max-width: 95vw;
}
</style>

<script>
export default {
    name: "Login",
    data: function () {
        return {
            server_response: "N/A",
            loginPending: false,
            loginError: null,
            creds: {
                user: null,
                pass: null
            },
        }
    },
    methods: {
        login: async function () {
            if (this.loginPending) return;
            // Do the login
            this.loginPending = true;
            try {
                await this.$api.login(this.creds);
                this.loginPending = false;
                this.creds.pass = null;
                this.$router.push('/dash');
            } catch (e) {
                this.$log.debug("Login failed");
                this.$log.debug(e.response);
                this.loginError = e.response.data.err;
                this.creds.pass = null;
                this.loginPending = false;
            }
        },
    },
    computed: {
        submitButton: function () {
            return {
                classes: {
                    'is-loading': this.loginPending
                }
            }
        }
    }
}
</script>


