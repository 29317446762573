<template lang="pug">
.navbar.is-danger
    .container
        .navbar-brand
            router-link.navbar-item(to="/") {{brand}}
            .navbar-burger(@click="triggerMobileNav", :class="mobileNavActive")
                span
                span
                span
        .navbar-menu(:class="mobileNavActive")
            .navbar-start
            template(v-if="loggedIn")
                .navbar-end
                    //router-link.navbar-item(to="/dash" @click.native="triggerMobileNav") Dash
                    a.navbar-item(@click="logout") Log Out
            template(v-else)
                .navbar-end
                    a.navbar-item(href="mailto:coen.mcclelland+mca@gmail.com") Contact
</template>

<style scoped>

</style>

<script>
export default {
    name: "Navigation",
    data: function () {
        return {
            brand: "MCA ADMIN",
            mobileNavActive: {
                'is-active': false
            },
        }
    },
    methods: {
        triggerMobileNav: function () {
            this.mobileNavActive['is-active'] = !this.mobileNavActive['is-active'];
        },
        logout: async function () {
            await this.$api.logout();
            this.triggerMobileNav();
            this.$router.push('/');
        }
    },
    computed: {
        loggedIn: function () {
            return this.$user.is_authenticated;
        }
    }
}
</script>

